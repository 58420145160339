import React, { useState, useEffect ,useRef} from 'react';
import axios from 'axios';
import './BoxStockAdd.css'; // Kendi stil dosyanızı ekleyin
import Quagga from 'quagga'; // QuaggaJS'i içeri aktardık
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

// Modal stilini ayarlayın
Modal.setAppElement('#root'); // Root elemanınızı buraya ekleyin

const BoxStockAdd = ({ currentUser }) => {
  const videoRef = useRef(null);

  const [sku, setSku] = useState('');
  const [error, setError] = useState('');
  let [status, setStatus] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [textColor, setTextColor] = useState('');
  const navigate = useNavigate();
  let detectedBarcodes = [];

  const startCamera = () => {
    const video = document.getElementById('video');
    let scanTimeout;

    navigator.mediaDevices.getUserMedia({
      video: {
        // environment : arka kamera  user : ön kamera
        facingMode: { exact: 'environment' },
      },
    })
      .then((stream) => {
        video.srcObject = stream;
        Quagga.init({
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: video,
            constraints: {
              width: 640,
              height: 480,
            },
          },
        
          decoder: {
            readers: ['code_128_reader'],
          },
          
          locate: true,
          debug: false,
          halfSample: false, // Performansı artırmak için yarım örnekleme

          frequency: 50, // Tarama sıklığını ayarlayın
          
        }, (err) => {
          if (err) {
            console.error(err);
            return;
          }
          Quagga.onDetected((data) => {
            const barcode = data.codeResult.code;
  
            // Barkod daha önce algılanmadıysa listeye ekle
            if (!detectedBarcodes.includes(barcode)) {
              detectedBarcodes.push(barcode); // Yeni barkodu listeye ekle
            }
  
            // Tarama süresinin sonunda işlemi başlat
            if (!scanTimeout) {
              scanTimeout = setTimeout(() => {
                Quagga.stop();
                setLoadingModalIsOpen(true); // Loading modal'ı aç
                handleBarcodeDetected(detectedBarcodes); // Algılanan barkodları işle
              }, 2000); // Tarama işlemi 5 saniye sürecek
            }
          });
  
          Quagga.start();
        });
      })
      .catch((err) => {
        console.error('Kamera açılırken bir hata oluştu: ', err);
      });
  };

  const handleBarcodeDetected = async (barcode) => {

    setLoadingModalIsOpen(true); // Loading modal'ı aç

    try {
      const response = await axios.post('https://payprintgo.com:3001/api/boxstockadd', { SKU: barcode, currentUser: currentUser });
      setTextColor(response.data.status === 'red' ? 'red-text' : 'green-text');
      setStatus(response.data.result);
    } catch (error) {
      setError('Bir hata oluştu. Hata mesajı: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoadingModalIsOpen(false); // Loading modal'ı kapat
      setSku(''); // SKU'yu sıfırla
      detectedBarcodes = [];

    }
  };
  const handleFormSubmit = async (e) => {

    if (e) {
      e.preventDefault();
    }
    setLoadingModalIsOpen(true); // Loading modal'ı aç
    try {
      let SumbitSku = [];
      SumbitSku.push(sku); // Yeni barkodu listeye ekle
      const response = await axios.post('https://payprintgo.com:3001/api/boxstockadd', { SKU: SumbitSku  ,  currentUser: currentUser });
      setTextColor(response.data.status === 'red' ? 'red-text' : 'green-text');
      setLoadingModalIsOpen(false); // Loading modal'ı kapat
      setStatus(response.data.result);
      setSku(''); // SKU'yu sıfırla
    } catch (error) {
      setLoadingModalIsOpen(false); // Hata durumunda da loading modal'ı kapat
      setError('Bir hata oluştu. Hata mesajı: ' + (error.response?.data?.message || error.message));
    }
  };
  

  const handleModalClose = () => {
    setModalIsOpen(false);
    setStatus(''); // Modal kapatıldığında status'u sıfırla
    // Eğer kullanıcı SKU'yu tekrar tarayacaksa, kamerayı yeniden başlatabilirsiniz
    startCamera();
  };

  useEffect(() => {
    if (status === '') {
      startCamera();
    }
    if (status.includes("Ürün bulunamadı")) {
      setModalIsOpen(true);
    }
    if (status.includes("eklenmiştir")) {
      setModalIsOpen(true);
    }
    
  }, [status]);

  return (
   <div className="BoxStockAdd">
    <div className="container">
    <div className="row">
      <div className='col-12'></div>

      <div id='add' className="justify-content-center align-items-center">
        <div className="card">
          
          {error && <div className="text-danger">{error}</div>}
            <h1 id="Info">Have The Camera Scan The Barcode</h1>
            <div style={{ position: 'relative', display: 'inline-block' }}>
      <video
        ref={videoRef}
        id="video"
        autoPlay
        style={{ display: 'block', margin: '0 auto', width: '100%' }}
      ></video>
     
     </div>

            <form id="photoForm" method="post" onSubmit={handleFormSubmit} className="form-group">
               <input id="SkuText" name="SKU" value={sku} onChange={(e) => setSku(e.target.value)} />
              <button type="submit" className="btn btn-primary">Submit</button>
           </form>
           <button 
           id="TurnBack"
            onClick={() => navigate('/selection-screen')} 
           className="btn btn-primary back-button"
          >
            Turn Back
          </button>
         
        </div>

        
      </div>
      {/* Loading Modal */}
      <Modal className='loadingModal'
          isOpen={loadingModalIsOpen}
          shouldCloseOnOverlayClick={false} // Overlay tıklamasıyla kapanmayı engelle
          shouldCloseOnEsc={false} // ESC tuşuyla kapanmayı engelle
          onRequestClose={() => setLoadingModalIsOpen(false)}
          contentLabel="Loading Modal"
        >
          <div className="modal-body">
            <b>Please Wait. This process may take a long time</b>
          </div>
        </Modal>

        {/* Status Modal */}
        <Modal className='statusmodal'
          isOpen={modalIsOpen}
          onRequestClose={handleModalClose}
          contentLabel="Status Modal"
          
        >
          <div className="modal-header">
            <h5 className="modal-title">information</h5>
            <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p className={textColor} >{status}</p>
          </div>
          <div className="modal-footer">
            <button type="button" id="retryButton" className="btn btn-primary" onClick={handleModalClose}>Okey</button>
          </div>
        </Modal>
    </div>
    </div>
    </div>
  );
};

export default BoxStockAdd;
