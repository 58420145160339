import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StockReport.css';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const UsersDetails = () => {
    const [usersDetails, setUsersDetails] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [filteredDetails, setFilteredDetails] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://payprintgo.com:3001/api/getUsersName')
            .then(response => {
                setUserNames(response.data);
            })
            .catch(error => console.error('Kullanıcı adları alınırken bir hata oluştu:', error));

        axios.get('https://payprintgo.com:3001/api/getUsersDetails')
            .then(response => {
                const sortedData = response.data.sort((a, b) => b.Id - a.Id);
                setUsersDetails(sortedData);
                setFilteredDetails(sortedData);
            })
            .catch(error => console.error('Veri alınırken bir hata oluştu:', error));
    }, []);

    const handleFilter = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setDate(end.getDate() + 1);

        const filtered = usersDetails.filter(item => {
            const itemDate = new Date(item.Date);
            const nameMatch = selectedNames.length === 0 || selectedNames.includes(item.Name);
            return itemDate >= start && itemDate < end && nameMatch;
        });
 // Tarihe göre azalan sırayla sıralama
 const sorted = filtered.sort((a, b) => b.Id - a.Id);
 setFilteredDetails(sorted);
    };

    const handleReset = () => {
        setStartDate(new Date().toISOString().split('T')[0]);
        setEndDate(new Date().toISOString().split('T')[0]);
        setSelectedNames([]);
        setFilteredDetails(usersDetails);
    };

    const handleNameChange = (name) => {
        setSelectedNames(prev =>
            prev.includes(name) ? prev.filter(n => n !== name) : [...prev, name]
        );
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const generateExcel = () => {
        const data = filteredDetails.length > 0 ? filteredDetails : usersDetails;

        // Başlıkları oluştur
        const headers = ["Name", "Product", "Date", "Status", "Quantity"];
        const rows = data.map(item => [
            item.Name,
            item.Product,
            item.Date,
            item.Status,
            item.Quantity
        ]);

        // Excel verisini oluştur
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        // Excel dosyasını indir
        XLSX.writeFile(workbook, "data.xlsx");
    };

    return (
      <div className='StockReport'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="date-filter-group me-3">
                                <div className="date-filter">
                                    <label htmlFor="startDate" className="form-label">Start Date</label>
                                    <input type="date" id="startDate" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className="date-filter">
                                    <label htmlFor="endDate" className="form-label">End Date</label>
                                    <input type="date" id="endDate" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                            </div>
                            <div id='nameDropdown' className="dropdown me-3">
                                <button className="btn btn-success" onClick={toggleDropdown}>
                                    Name ↓
                                </button>
                                {dropdownOpen && (
                                    <div className="dropdown-menu">
                                        {userNames.map(item => (
                                            <div key={item.Id} className="dropdown-item">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.Name}
                                                    id={`filter_${item.Name}`}
                                                    checked={selectedNames.includes(item.Name)}
                                                    onChange={() => handleNameChange(item.Name)}
                                                />
                                                <label className="form-check-label" htmlFor={`filter_${item.Name}`}>
                                                    {item.Name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button id='filterButton' className="btn btn-outline-secondary me-3" onClick={handleFilter}>Filter</button>
                            <button id='resetButton' className="btn btn-outline-danger me-3" onClick={handleReset}>Reset Filter</button>
                            <button id='exportButton' className="btn btn-outline-primary me-3" onClick={generateExcel}>Export to Excel</button>

                        </div>
                        <button 
           id="BackButton"
      onClick={() => navigate('/selection-screen')} 
      className="btn btn-outline-dark back-button"
    >
      Turn Back
    </button>                     </div>
                    <br />
                    <div className="accordion">
                        <div className="accordion-item">
                            <div className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-secondary">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th><strong>Name</strong></th>
                                                    <th><strong>Product</strong></th>
                                                    <th><strong>Date</strong></th>
                                                    <th><strong>Status</strong></th>
                                                    <th><strong>Count</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredDetails.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.Name}</td>
                                                        <td>{item.Product}</td>
                                                        <td>{item.Date}</td>
                                                        <td className={item.Status === "ADD" ? "green-background" : item.Status === "USED" ? "red-background" : ""}>
                                                            {item.Status}
                                                        </td>
                                                        <td>{item.Quantity}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
    );
};

export default UsersDetails;
