import React, { useState, useEffect } from 'react';
import './BoxStockDetails.css';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

// Register components
ChartJS.register(ArcElement, Tooltip, Legend);

function BoxStockDetails({ currentUser }) {
    const [isChange, setisChange] = useState(false); // Yeni boolean state
    let [dataBoxStock, setDataBoxStock] = useState([]);
    useEffect(() => {
        if (currentUser) {
            axios.get('https://payprintgo.com:3001/api/boxstockGet')
                .then(response => {
                    setDataBoxStock(response.data);
                    let successCount = 0;
           let dangerCount = 0;
          let primaryCount = 0;

          response.data.forEach(item => {
            if (item.Stock > item.MustHaveStock) {
                successCount++;
            } else if (item.Stock < item.MustHaveStock) {
                dangerCount++;
            } else {
                primaryCount++;
            }
          });

           setSuccessStock(successCount);
           setDangerStock(dangerCount);
            setPrimaryStock(primaryCount);
                })
                .catch(error => {
                    console.error('Veri alma hatası:', error);
                });
                setisChange(false);

        }
    }, [isChange]);

    const [count] = useState(1);
    const [skuSearch, setSkuSearch] = useState('');
    const [successStock, setSuccessStock] = useState(0);
    const [dangerStock, setDangerStock] = useState(0);  
    const [primaryStock, setPrimaryStock] = useState(0);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [AddSKUModalIsOpen, setAddSKUModalIsOpen] = useState(false);
    const [UpdateSKUModalIsOpen, setUpdateSkuModalIsOpen] = useState(false);

    const navigate = useNavigate();


    // Modal'da kullanılacak form alanları
    const [ADDShopId, setSHopId] = useState('');
    const [ADDskuName, setSkuName] = useState('');
    const [ADDskuInput, setSkuInput] = useState('');
    const [ADDstockInput, setStockInput] = useState('');
    const [ADDmustHaveStockInput, setMustHaveStockInput] = useState('');

    //update modal de kullanacak form alanları
    const [UpdateShopId, setUpdateSHopId] = useState('');
    const [UpdateskuName, setUpdateSkuName] = useState('');
    const [UpdateskuInput, setUpdateSkuInput] = useState('');
    // veri tabınında sku değişiceği zaman eski Sku yu değişkende tutmak zorundayım ki hangi sku yu değiştirdiğini bilelim
    const [UpdateOldSku, setUUpdateOldSku] = useState('');

    const handleIncrement = (index) => {
        const sku = filteredData[index].SKU;
        const originalIndex = dataBoxStock.findIndex(item => item.SKU === sku);
        
        if (originalIndex !== -1) {
            const newData = [...dataBoxStock];
            newData[originalIndex].Stock += 1;
            setDataBoxStock(newData);
        }
    };
    
    const handleDecrement = (index) => {
        const sku = filteredData[index].SKU;
        const originalIndex = dataBoxStock.findIndex(item => item.SKU === sku);
        
        if (originalIndex !== -1) {
            const newData = [...dataBoxStock];
            newData[originalIndex].Stock -= 1;
            setDataBoxStock(newData);
        }
    };
    
    const handleStockChange = (event, index) => {
        const newValue = event.target.value;
        const sku = filteredData[index].SKU;
        const originalIndex = dataBoxStock.findIndex(item => item.SKU === sku);
        
        if (originalIndex !== -1) {
            const newData = [...dataBoxStock];
            newData[originalIndex].Stock = parseInt(newValue, 10);
            setDataBoxStock(newData);
        }
    };
    
    const handleMustHaveStockChange = (event, index) => {
        const newValue = event.target.value;
        const sku = filteredData[index].SKU;
        const originalIndex = dataBoxStock.findIndex(item => item.SKU === sku);
        
        if (originalIndex !== -1) {
            const newData = [...dataBoxStock];
            newData[originalIndex].MustHaveStock = parseInt(newValue, 10);
            setDataBoxStock(newData);
        }
    };

    const handleSubmitAddSKU = (e) => {
        setAddSKUModalIsOpen(false);
        setLoadingModalIsOpen(true); // Loading modal'ı kapat
        e.preventDefault();
    
        // Form verilerini al
        const skuData = {
            Name: ADDskuName,
            SKU: ADDskuInput,
            Stock: ADDstockInput,
            MustHaveStock: ADDmustHaveStockInput,
            ShopId : ADDShopId,
            CurrentUser:currentUser
        };
    
        // API'ye POST isteği gönder
        axios.post('https://payprintgo.com:3001/api/addSKU', skuData)
            .then((response) => {
                setSkuName('');
                setSkuInput('');
                setStockInput('');
                setSHopId('');
                setMustHaveStockInput('');
                alert("Transaction successful");
                setisChange(true);                
                setLoadingModalIsOpen(false); // Loading modal'ı kapat


            })
            .catch((error) => {
                console.error('SKU eklenirken hata oluştu:', error);
                setLoadingModalIsOpen(false); // Loading modal'ı kapat

                // Opsiyonel: Hata mesajını kullanıcıya göster
            });
    };
    const handleApply = (sku, name, stock, mustHaveStock) => {
        setLoadingModalIsOpen(true); // Loading modal'ı kapat


        const url = 'https://payprintgo.com:3001/api/UpdateMustHaveStockAndStock';
        const data = {
            SKU: sku,
            mustHaveStock: mustHaveStock,
            Stock: stock,
            Name: name,
            CurrentUser: currentUser
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            setLoadingModalIsOpen(false);
            if (response.ok) {
                // Sayfa yeniden yüklendikten sonra yönlendirme yap
                alert("Transaction successful");
                setisChange(true);
             } else {
                console.error('Hata:', response.statusText);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setLoadingModalIsOpen(false);
        });
    };
    async function handleExport() {
        try {
            const response = await axios.post('https://payprintgo.com:3001/api/exportToExcel', filteredData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'blob'
            });
    
            // Excel dosyasını indirme
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'FilteredBoxStockData.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    }
    const filteredData = dataBoxStock.filter((item) => {
        const lowerSku = item.SKU.toLowerCase();
        const lowerName = item.Name.toLowerCase();
        const searchLower = skuSearch.toLowerCase();
        return (
            (
            skuSearch === 'all' ||
        (skuSearch === 'success' && item.Stock > item.MustHaveStock) ||
        (skuSearch === 'danger' && item.Stock < item.MustHaveStock )||
        (skuSearch === 'primary' && item.Stock === item.MustHaveStock))||
            (lowerSku.includes(searchLower) ||
            lowerName.includes(searchLower) ||
            item.Stock.toString().includes(searchLower) ||
            item.MustHaveStock.toString().includes(searchLower) || item.ShopId.toString().includes(searchLower))
            
        );
    });


    const data = {
        labels: ['Stock > MustHaveStock', 'Stock < MustHaveStock', 'Stock = MustHaveStock'],
        datasets: [
            {
                label: 'Stock Durumu',
                data: [successStock, dangerStock, primaryStock],
                backgroundColor: ['#28a745', '#dc3545', '#007bff'],
                hoverBackgroundColor: ['#218838', '#c82333', '#0069d9']
            }
        ]
    };
  // Renk filtreleme fonksiyonu
  const handleFilterByColor = (color) => {
    setSkuSearch(color);
  }; 

 // Renk filtreleme fonksiyonu
 const ResetSearchChart = () => {
    setSkuSearch("");
  };

   const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
    
      const data = {
        jsonData: jsonData,
        currentUser: currentUser // currentUser değerini burada kullanıyoruz
      };
      // API'ye gönder
      axios
        .post('https://payprintgo.com:3001/api/ExcelImport', data)
        .then(() => {
                // Sayfa yeniden yüklendikten sonra yönlendirme yap
                // Bu yönlendirme, veri güncellemesinden önce yapılmalı
                alert("Transaction successful");
                setisChange(true);

        })
        .catch((error) => {
           // Hata durumunda
        if (error.response && error.response.data && error.response.data.message) {
            alert('Hata: ' + error.response.data.message);
            setisChange(true);            // Geçici bir yönlendirme yapın
 
          } 
        });
    };
  
    reader.readAsBinaryString(file);
  };
  const generateExcel = () => {
    // Excel için başlıkları ve verileri içeren dizi
    const data = [
      ["ShopId", "SKU", "Name", "Stock", "Must Have Stock"], // Başlıklar
    ];
  
    // Filtrelenmiş verileri ekleyin
    dataBoxStock.forEach(item => {
      data.push([
        item.ShopId,
        item.SKU,
        item.Name,
      ]);
    });
  
    // Yeni bir çalışma kitabı oluştur
    const workbook = XLSX.utils.book_new();
  
    // Çalışma sayfası oluştur ve veri ekle
    const worksheet = XLSX.utils.aoa_to_sheet(data);
  
    // Çalışma sayfasını çalışma kitabına ekle
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sayfa1");
  
    // Çalışma kitabını Excel dosyası olarak yaz
    XLSX.writeFile(workbook, "sample.xlsx");
  };
  const handleSubmitUpdateSKU = (e) => {
    setUpdateSkuModalIsOpen(false);
    setLoadingModalIsOpen(true); // Loading modal'ı kapat
    e.preventDefault();
    // Form verilerini al
    const skuData = {
        Name: UpdateskuName,
        SKU: UpdateskuInput,
        ShopId : UpdateShopId,
        OldSku : UpdateOldSku
    };

    // API'ye POST isteği gönder
    axios.post('https://payprintgo.com:3001/api/UpdateSKU', skuData)
        .then((response) => {
            setUpdateSkuName('');
            setUpdateSkuInput('');
            setUpdateSHopId('');
            setUUpdateOldSku('');
            alert("Transaction successful");
            setisChange(true);        
            setLoadingModalIsOpen(false); // Loading modal'ı kapat
        

        })
        .catch((error) => {
            console.error('SKU güncellenirken hata oluştu:', error);
            setLoadingModalIsOpen(false); // Loading modal'ı kapat

            // Opsiyonel: Hata mesajını kullanıcıya göster
        });
   };

   const handleDeleteSku = (sku) => {
    setLoadingModalIsOpen(true); // Loading modal'ı kapat
    // Form verilerini al
    const skuData = {
        SKU: sku
    };

    // API'ye POST isteği gönder
    axios.post('https://payprintgo.com:3001/api/DeleteSKU', skuData)
        .then((response) => {
            alert("Transaction successful");
            setisChange(true);        
            setLoadingModalIsOpen(false); // Loading modal'ı kapat
        

        })
        .catch((error) => {
            console.error('SKU güncellenirken hata oluştu:', error);
            setLoadingModalIsOpen(false); // Loading modal'ı kapat

            // Opsiyonel: Hata mesajını kullanıcıya göster
        });
   };

    return (
        <div className='BoxStockDetails'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-9">
                        <div className="input-group d-flex justify-content-between">
                            
                            <input
                                type="text"
                                className="form-control"
                                id="skuSearch"
                                placeholder="Search by SKU, Name, Stock or Must Have Stock"
                                value={skuSearch}
                                onChange={(e) => setSkuSearch(e.target.value)}
                            />
                            <button
                                id="button"
                                onClick={handleExport}
                                className="Export-button"
                            >
                                Export to Excel
                            </button>
                          
                             <button className="btn btn-danger CreateTemplateButton" onClick={generateExcel}>
                             Create Excel Template
                             </button>
                            <button id='İmportExcelButton' className="btn btn-light" onClick={() => document.getElementById('fileInput').click()}>
                            Import to Excel
                             </button>
<input
  type="file"
  accept=".xlsx, .xls"
  id="fileInput"
  style={{ display: 'none' }}
  onChange={handleFileUpload}
/>
                            <button id="addSkuButton" className="btn btn-success"  onClick={() => setAddSKUModalIsOpen(true)}>
                                ADD SKU
                            </button>
                            <button 
           id="TurnBack"
      onClick={() => navigate('/selection-screen')} 
      className="btn btn-secondary back-button"
    >
      Turn Back
    </button>
                        </div>
                        <br />
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item" id="accordion_item_SkuLoadingScreen">
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div id="accordion-body" className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-secondary" id="skuTable">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th><strong>Shop ID</strong></th>
                                                        <th><strong>SKU</strong></th>
                                                        <th><strong>Name</strong></th>
                                                        <th><strong> </strong></th>
                                                        <th><strong>Stock</strong></th>
                                                        <th></th>
                                                        <th><strong>Must Have Stock</strong></th>
                                                        <th></th>
                                                        <th><strong>Number Of Missing Boxes</strong></th>
                                                        <th><strong>Extra Box</strong></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((item, index) => {
                                                        let rowClass = '';
                                                        
                                                        if (item.Stock > item.MustHaveStock) {
                                                            rowClass = 'table-success'; 
                                                        } else if (item.Stock < item.MustHaveStock) {
                                                            rowClass = 'table-danger'; 
                                                        } else {
                                                            rowClass = 'table-primary'; 
                                                        }
                                                    
                                                        const missingBoxes = item.MustHaveStock - item.Stock;
                                                        const excessBoxes = item.Stock - item.MustHaveStock;

                                                        return (
                                                            <tr className={rowClass} key={index}>
                                                                <td>{count + index}</td>
                                                                <td>{item.ShopId}</td>
                                                                <td>{item.SKU}</td>
                                                                <td>{item.Name}</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-outline-secondary decrement-button"
                                                                        type="button"
                                                                        onClick={() => handleDecrement(index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control stock-input"
                                                                        value={item.Stock}
                                                                        onChange={(e) =>
                                                                            handleStockChange(e, index)
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-outline-secondary increment-button"
                                                                        type="button"
                                                                        onClick={() => handleIncrement(index)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control must-have-stock"
                                                                        value={item.MustHaveStock}
                                                                        onChange={(e) =>
                                                                            handleMustHaveStockChange(e, index)
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        id="ApplyButton"
                                                                        onClick={() =>
                                                                            handleApply(
                                                                                item.SKU,
                                                                                item.Name,
                                                                                item.Stock,
                                                                                item.MustHaveStock
                                                                            )
                                                                        }
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </td>
                                                               
                                                                <td>{missingBoxes > 0 ? missingBoxes : 0}</td>
                                                                <td>{excessBoxes > 0 ? excessBoxes : 0}</td>
                                                                <td>
                                                                    <button
                                                                        id="UpdateButton"
                                                                        onClick={() => {
                                                                            setUpdateSHopId(item.ShopId);
                                                                            setUpdateSkuName(item.Name);
                                                                            setUpdateSkuInput(item.SKU);
                                                                            setUpdateSkuModalIsOpen(true);
                                                                            setUUpdateOldSku(item.SKU);
                                                                          }}                                                                          
                                                                    >
                                                                        Update
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        id="DeleteButton"
                                                                        onClick={() => {
                                                                            handleDeleteSku(item.SKU);
                                                                            
                                                                          }}                                                                          
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        
                    </div>
                    <div className='col-3'>
                    <Pie className='chart'
            data={data} 
        />
                            <button id='searchButtonChart' className="btn btn-secondary" onClick={() => handleFilterByColor('all')}>
                                All
                            </button>
                            <button id='searchButtonChart' className="btn btn-primary" onClick={() => handleFilterByColor('primary')}>
                            Sufficient Stock
                            </button>
                            <button id='searchButtonChart' className="btn btn-danger" onClick={() => handleFilterByColor('danger')}>
                            Danger Stock
                            </button>
                            <button id='searchButtonChart' className="btn btn-success" onClick={() => handleFilterByColor('success')}>
                            Extra Stock
                            </button>
                            <button id='searchButtonChart' className="btn btn-dark" onClick={() => ResetSearchChart()}>
                            Reeset Filter
                            </button>
        
                        </div>
       
                </div>
                   {/* Loading Modal */}
         <Modal className='loadingModal'
          isOpen={loadingModalIsOpen}
          shouldCloseOnOverlayClick={false} // Overlay tıklamasıyla kapanmayı engelle
          shouldCloseOnEsc={false} // ESC tuşuyla kapanmayı engelle
          onRequestClose={() => setLoadingModalIsOpen(false)}
          contentLabel="Loading Modal"
        >
          <div className="modal-body">
            <b>Please Wait. This process may take a long time</b>
          </div>
        </Modal>
        {/* Add SKU Modal */}
        <Modal className={'AddSKUModal'}
                isOpen={AddSKUModalIsOpen}
                onRequestClose={() => setAddSKUModalIsOpen(false)}
                contentLabel="Add SKU Modal"
            >
                <div className="add-sku-modal-content">
                    <h2>Add SKU</h2>
                    <form onSubmit={handleSubmitAddSKU}>
                    <div>
                            <label>Shop Id:</label>
                            <input
                                type="text"
                                value={ADDShopId}
                                onChange={(e) => setSHopId(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU Name:</label>
                            <input
                                type="text"
                                value={ADDskuName}
                                onChange={(e) => setSkuName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU:</label>
                            <input
                                type="text"
                                value={ADDskuInput}
                                onChange={(e) => setSkuInput(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Stock:</label>
                            <input
                                type="number"
                                value={ADDstockInput}
                                onChange={(e) => setStockInput(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Must Have Stock:</label>
                            <input
                                type="number"
                                value={ADDmustHaveStockInput}
                                onChange={(e) => setMustHaveStockInput(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-success">
                            Add SKU
                        </button>
                      
                    </form>
                    <br />
                    <button id='ModalCloseButton' onClick={() => setAddSKUModalIsOpen(false)} className="btn btn-secondary">
                        Cancel
                    </button>
                </div>
            </Modal>
            <Modal className={'AddSKUModal'}
                isOpen={UpdateSKUModalIsOpen}
                onRequestClose={() => setUpdateSkuModalIsOpen(false)}
                contentLabel="Add SKU Modal"
            >
                
                <div className="add-sku-modal-content">
                    <h2>Update SKU</h2>
                    <form onSubmit={handleSubmitUpdateSKU}>
                    <div>
                            <label>Shop Id:</label>
                            <input
                                type="text"
                                value={UpdateShopId}
                                onChange={(e) => setUpdateSHopId(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU Name:</label>
                            <input
                                type="text"
                                value={UpdateskuName}
                                onChange={(e) => setUpdateSkuName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>SKU:</label>
                            <input
                                type="text"
                                value={UpdateskuInput}
                                onChange={(e) => setUpdateSkuInput(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-success">
                            Update SKU
                        </button>
                      
                    </form>
                    <br />
                    <button id='ModalCloseButton' onClick={() => setUpdateSkuModalIsOpen(false)} className="btn btn-secondary">
                        Cancel
                    </button>
                </div>
            </Modal>
            </div>
        </div>
    );
}

export default BoxStockDetails;