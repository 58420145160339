import React, { useState, useEffect} from 'react';
import axios from 'axios';
import './UserSetting.css'; // Kendi stil dosyanızı ekleyin
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
// Modal stilini ayarlayın
Modal.setAppElement('#root'); // Root elemanınızı buraya ekleyin

const UserSettings = ({ currentUser }) => {

  let [UserDetail, setUserDetails] = useState([]);
  const [isChange, setisChange] = useState(false); // Yeni boolean state
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [AddUserModalIsOpen, setAddUserModalIsOpen] = useState(false);

    //update modal de kullanacak form alanları
    const [İnputName, setİnputName] = useState('');
    const [İnputEmail, setİnputEmail] = useState('');
    const [inputPassword, setinputPassword] = useState('');
    const [inputIsAdmin, setUinputIsAdmin] = useState(false);
    const [inputBoxStockAddAccess, setinputBoxStockAddAccess] = useState(false);
    const [inputBoxStockDeleteAcces, setinputBoxStockDeleteAcces] = useState(false);
    const [inputBoxStockDetailsAccess, setinputBoxStockDetailsAccess] = useState(false);
    const [inputStockReportAccess ,setinputStockReportAccess] = useState(false);
    const [inputUserSettingsAccess, setinputUserSettingsAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if (currentUser) {
          axios.get('https://payprintgo.com:3001/api/UserGet')
              .then(response => {
                setUserDetails(response.data);
              })
              .catch(error => {
                  console.error('Veri alma hatası:', error);
              });
              setisChange(false);

      }
  }, [isChange]);

  const handleApply = (id) => {
    setLoadingModalIsOpen(true); // Loading modal'ı kapat
    const url = 'https://payprintgo.com:3001/api/UpdateUser';
    const user = UserDetail.find(user => user.Id === id);
    const data = {
      Id: user.Id,
      Name: user.Name,
      Email: user.Email,
      Password: user.Password,
      IsAdmin: user.IsAdmin,
      BoxStockAddAccess: user.BoxStockAddAccess,
      BoxStockDeleteAccess: user.BoxStockDeleteAccess,
      BoxStockDetailsAccess: user.BoxStockDetailsAccess,
      StockReportAccess: user.StockReportAccess,
      UserSettingsAccess: user.UserSettingsAccess
    };
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        setLoadingModalIsOpen(false);
        if (response.ok) {
            // Sayfa yeniden yüklendikten sonra yönlendirme yap
            alert("Transaction successful");
            setisChange(true);
         } else {
            console.error('Hata:', response.statusText);
        }
    })
    .catch(error => {
        console.error('Error:', error);
        setLoadingModalIsOpen(false);
    });
};
const handleInputChange = (id, field, value) => {

  setUserDetails(prevDetails =>
    prevDetails.map(user =>
      user.Id === id ? { ...user, [field]: value } : user
    )
  );
};

const handleSubmitAddUser = (e) => {
  setAddUserModalIsOpen(false);
  setLoadingModalIsOpen(true); // Loading modal'ı kapat
  e.preventDefault();
  
  // Form verilerini al
  const UserData = {
    Name: İnputName,
    Email: İnputEmail,
    Password: inputPassword,
    IsAdmin: inputIsAdmin,
    BoxStockAddAccess: inputBoxStockAddAccess,
    BoxStockDeleteAccess: inputBoxStockDeleteAcces,
    BoxStockDetailsAccess: inputBoxStockDetailsAccess,
    StockReportAccess: inputStockReportAccess,
    UserSettingsAccess: inputUserSettingsAccess
  };

  // API'ye POST isteği gönder
  axios.post('https://payprintgo.com:3001/api/addUser', UserData)
      .then((response) => {
        setİnputName('');
        setİnputEmail('');
        setinputPassword('');
        setUinputIsAdmin('');
        setinputBoxStockAddAccess('');
        setinputBoxStockDeleteAcces('');
        setinputBoxStockDetailsAccess('');
        setinputStockReportAccess('');
        setinputUserSettingsAccess('');
          alert("Transaction successful");
          setisChange(true);                
          setLoadingModalIsOpen(false); // Loading modal'ı kapat


      })
      .catch((error) => {
          console.error('SKU eklenirken hata oluştu:', error);
          setLoadingModalIsOpen(false); // Loading modal'ı kapat

          // Opsiyonel: Hata mesajını kullanıcıya göster
      });
};

const handleADeleteUser = (id) => {
  setLoadingModalIsOpen(true); // Loading modal'ı kapat
  const url = 'https://payprintgo.com:3001/api/DeleteUser';
  const user = UserDetail.find(user => user.Id === id);
  const data = {
    Id: user.Id
  };
  fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
  })
  .then(response => {
      setLoadingModalIsOpen(false);
      if (response.ok) {
          // Sayfa yeniden yüklendikten sonra yönlendirme yap
          alert("Transaction successful");
          setisChange(true);
       } else {
          console.error('Hata:', response.statusText);
      }
  })
  .catch(error => {
      console.error('Error:', error);
      setLoadingModalIsOpen(false);
  });
};
  return (
   <div className="UserSettings">
    <div className="container">
    <div className="row">
      <div className='col-12'>
      <br />
      <div className="input-group d-flex justify-content-between">
      

                            <button id="addUserButton" className="btn btn-success"  onClick={() => setAddUserModalIsOpen(true)}>
                                Add User
                            </button>
                                 <button 
                                    id="TurnBack"
                                    onClick={() => navigate('/selection-screen')} 
                                   className="btn btn-secondary back-button"
                                   >
                                  Turn Back
                               </button>
                        </div>
                        <br />
      <div className="accordion" id="accordionExample">
                            <div className="accordion-item" id="accordion_item_SkuLoadingScreen">
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div id="accordion-body" className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-secondary" id="skuTable">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th><strong>User Name</strong></th>
                                                        <th><strong>Email</strong></th>
                                                        <th><strong>Password</strong></th>
                                                        <th><strong>İs Admin</strong></th>
                                                        <th><strong>Box Stock Add Access</strong></th>
                                                        <th><strong>Box Stock Delete Access </strong></th>
                                                        <th><strong>Box Stock Details Access</strong></th>
                                                        <th>Stock Report Access</th>
                                                        <th><strong>User Settings Access</strong></th>
                                                        <th></th>
                                                        <th></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                          {UserDetail.map((item, index) => {
                            return (
                              <tr key={index}>
                                {/* Form içerisine aldığımız input text alanları */}
                                <td>{(index + 1)}</td>
                                <td>
                                  <input
                                    type="text"
                                    value={item.Name}
                                    onChange={(e) => handleInputChange(item.Id, 'Name', e.target.value)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item.Email}
                                    onChange={(e) => handleInputChange(item.Id, 'Email', e.target.value)}
                                  />
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    value={item.Password}
                                    onChange={(e) => handleInputChange(item.Id, 'Password', e.target.value)}
                                  />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.IsAdmin === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'IsAdmin', e.target.checked ? 1 : 0)}
                                  />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.BoxStockAddAccess === 1} // `item.BoxStockAddAccess` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'BoxStockAddAccess', e.target.checked ? 1 : 0)}
                                    />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.BoxStockDeleteAccess === 1} // `item.BoxStockDeleteAcces` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'BoxStockDeleteAccess', e.target.checked ? 1 : 0)}
                                    />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.BoxStockDetailsAccess === 1} // `item.BoxStockDetailsAccess` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'BoxStockDetailsAccess', e.target.checked ? 1 : 0)}
                                    />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.StockReportAccess === 1} // `item.StockReportAccess` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'StockReportAccess', e.target.checked ? 1 : 0)}
                                    />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={item.UserSettingsAccess === 1} // `item.UserSettingsAccess` değeri 1 ise işaretli
                                    onChange={(e) => handleInputChange(item.Id, 'UserSettingsAccess', e.target.checked ? 1 : 0)}
                                    />
                                </td>

                                <td>
                                  <button
                                    id="UpdateButton"
                                    onClick={() => handleApply(item.Id)}
                                  >
                                    Update
                                  </button>
                                </td>
                                <td>
                                  <button
                                    id="DeleteUserButton"
                                    onClick={() => handleADeleteUser(item.Id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


    </div>
    </div>
      {/* Loading Modal */}
      <Modal className='loadingModal'
          isOpen={loadingModalIsOpen}
          shouldCloseOnOverlayClick={false} // Overlay tıklamasıyla kapanmayı engelle
          shouldCloseOnEsc={false} // ESC tuşuyla kapanmayı engelle
          onRequestClose={() => setLoadingModalIsOpen(false)}
          contentLabel="Loading Modal"
        >
          <div className="modal-body">
            <b>Please Wait. This process may take a long time</b>
          </div>
        </Modal>
        {/* Add SKU Modal */}
        <Modal className={'AddUserModal'}
                isOpen={AddUserModalIsOpen}
                onRequestClose={() => setAddUserModalIsOpen(false)}
                contentLabel="Add User Modal"
            >
                <div className="add-User-modal-content">
                    <h2>Add User</h2>
                    <form onSubmit={handleSubmitAddUser}>
                        <div>
                            <label>Name :</label>
                            <input
                                    type="text"
                                    value={İnputName}
                                    onChange={(e) => setİnputName(e.target.value)}
                                    required
                                  />
                                   
                        </div>
                        <div>
                            <label>Email :</label>
                            <input
                                type="email"
                                value={İnputEmail}
                                onChange={(e) => setİnputEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Password :</label>
                            <input
                                type="text"
                                value={inputPassword}
                                onChange={(e) => setinputPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Is Admin :</label>
                            <input
                                type="checkbox"
                                checked={inputIsAdmin === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setUinputIsAdmin(e.target.checked ? 1 : 0)}                               
                            />
                        </div>
                        <div>
                            <label>Box Stock Add Access :</label>
                            <input
                                type="checkbox"
                                checked={inputBoxStockAddAccess === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setinputBoxStockAddAccess(e.target.checked ? 1 : 0)}                              
                            />
                        </div>
                        <div>
                            <label>Box Stock Delete Acces :</label>
                            <input
                                type="checkbox"
                                checked={inputBoxStockDeleteAcces === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setinputBoxStockDeleteAcces(e.target.checked ? 1 : 0)}
                                
                            />
                        </div><div>
                            <label>Box Stock Details Access :</label>
                            <input
                                type="checkbox"
                                checked={inputBoxStockDetailsAccess === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setinputBoxStockDetailsAccess(e.target.checked ? 1 : 0)}
                                
                            />
                        </div>
                        <div>
                            <label>Stock Report Access :</label>
                            <input
                                type="checkbox"
                                checked={inputStockReportAccess === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setinputStockReportAccess(e.target.checked ? 1 : 0)}
                                
                            />
                        </div>
                        <div>
                            <label>User Settings Access :</label>
                            <input
                                type="checkbox"
                                checked={inputUserSettingsAccess === 1} // `item.IsAdmin` değeri 1 ise işaretli
                                onChange={(e) => setinputUserSettingsAccess(e.target.checked ? 1 : 0)}
                                
                            />
                        </div>  
                        <button type="submit" className="btn btn-success">
                            Add User
                        </button>
                      
                    </form>
                    <br />
                    <button id='ModalCloseButton' onClick={() => setAddUserModalIsOpen(false)} className="btn btn-secondary">
                        Cancel
                    </button>
                </div>
            </Modal>
    </div>
    </div>
  );
};

export default UserSettings;
