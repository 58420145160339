// src/SelectionScreen.js
import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectionScreen.css'; // CSS dosyasını import edin

const SelectionScreen = (currentUser) => {
  const navigate = useNavigate(); // navigate fonksiyonunu alın
  const [userId, setUserId] = useState(null); // Kullanıcı ID'sini saklayacak state
  useEffect(() => {
    if (currentUser) {
      const storedUser = sessionStorage.getItem('user');

           if (storedUser) {
           // Veriyi JSON formatından nesneye dönüştür
           setUserId(JSON.parse(sessionStorage.getItem('userInfo')));
           }
    }
}, []);
  return (
    <div className="selection-screen">
      <div className="container">
        <div className="row justify-content-center align-items-center">
        {userId && userId.BoxStockAddAccess === 1 && (

          <div className="card text-bg-dark text-center" onClick={() => navigate('/box-stock-add')}>
            <h2 id="TransformText">Box Stock Add</h2>
          </div>
          )}

          {userId && userId.BoxStockDeleteAccess === 1 && (

          <div className="card text-bg-dark text-center" onClick={() => navigate('/box-stock-delete')}>
            <h2 id="TransformText">Box Stock Use</h2>
          </div>
          )}

          {userId && userId.BoxStockDetailsAccess === 1 && (

          <div className="card text-bg-dark text-center" onClick={() => navigate('/box-stock-details')}>
            <h2 id="TransformText">Box Stock Detail</h2>
          </div>
           
           )}

          {userId && userId.StockReportAccess === 1 && (

          <div className="card text-bg-dark text-center" onClick={() => navigate('/stock-report')}>
            <h2 id="TransformText">Stock Report</h2>
          </div>

           )}

          {userId && userId.UserSettingsAccess === 1 && (

          <div className="card text-bg-dark text-center" onClick={() => navigate('/user-settings')}>
            <h2 id="TransformText">User Settings</h2>
          </div>
           
           )}
        </div>
      </div>
    </div>
  );
};

export default SelectionScreen;
