import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css'; // Stil dosyanızı import edin

function Layout({ children, currentUser }) {
  const handleLogout = () => {
    sessionStorage.removeItem('user'); // 'user' anahtarını kaldır
    window.location.reload(true);
  };
  return (
    <div>
      <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <ul className="list-unstyled">
              {!currentUser ? (
                <li>
                  <Link to="/login" className="custom-login-link">Login</Link>
                </li>
              ) : (
                <div className="dropdown ">
                  <svg xmlns="https://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <circle cx="12" cy="7" r="4" fill="#76c7c0" />
    <path d="M12 11c-4 0-6 2-6 5v1h12v-1c0-3-2-5-6-5z" fill="#76c7c0" />
    
  </svg>
  <span className="user-greeting">{currentUser}</span>

                  <div className="dropdown-content">
                  <button onClick={handleLogout} className="logout-link">Logout</button>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <main className="main-content">{children}</main>
    </div>
    
  );
  
}

export default Layout;
