import React, {useEffect , useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Layout from './Layout';  
import SelectionScreen from './SelectionScreen';
import BoxStockAdd from './BoxStockAdd';
import BoxStockDelete from './BoxStockDelete';
import BoxStockDetails from './BoxStockDetails';
import StockReport from './StockReport';
import UserSettings from './UserSettings';


function App() {
    // currentUser için state tanımlayın
  const [currentUser, setCurrentUser] = useState(null);
  // useEffect kullanarak sessionStorage'i izleyin
  useEffect(() => {
    // Eğer sessionStorage'de bir kullanıcı varsa, state'i güncelleyin
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
       
    }
  }, []); // Boş bir bağımlılık dizisi ile sadece bileşen mount edildiğinde çalışır
  return (
    <Router> {/* basename burada tanımlanıyor */}
      <Routes>
        
        {/* Layout'u kullanarak oluşturulan rotalar */}
        <Route
          path="/selection-screen"
          element={currentUser ? <Layout currentUser={currentUser}><SelectionScreen currentUser={currentUser} /></Layout>  : <Navigate to="/login" /> }
        />
        <Route
          path="/box-stock-add"
          element={currentUser ? <Layout currentUser={currentUser}><BoxStockAdd currentUser={currentUser} /></Layout> : <Navigate to="/login" />}
        />
        <Route
          path="/box-stock-delete"
          element={currentUser ? <Layout currentUser={currentUser}><BoxStockDelete currentUser={currentUser}/></Layout> : <Navigate to="/login" />}
        />
        <Route
          path="/box-stock-details"
          element={currentUser ? <Layout currentUser={currentUser}><BoxStockDetails currentUser={currentUser} /></Layout> : <Navigate to="/login" />}
        />
        <Route
          path="/stock-report"
          element={currentUser ? <Layout currentUser={currentUser}>< StockReport/></Layout> : <Navigate to="/login" />}
        />
         <Route
          path="/user-settings"
          element={currentUser ? <Layout currentUser={currentUser}>< UserSettings currentUser={currentUser}/></Layout> : <Navigate to="/login" />}
        />
        {/* Login sayfası için Layout kullanmıyoruz */}
        <Route path="/login"          
         element={currentUser ? <Navigate to="/selection-screen" />: <Login />}
 />
        
         <Route
          path="*"
          element={currentUser ? <Navigate to="/selection-screen" /> : <Navigate to="/login" />}
        /> 
      </Routes>
    </Router>
  );
}

export default App;
